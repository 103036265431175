import styled from '@emotion/styled';

export const Article = styled.article`
  .gatsby-image-wrapper {
    ${({ theme }) => theme.media.mobile} {
      img {
        border-radius: 0;
      }
    }
  }

  h1 {
    ${({ theme }) => theme.media.mobile} {
      font-size: 26px;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.articleHeadingColor};
  }
`;

import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import slugs from 'github-slugger';
import { ThemeProvider } from 'emotion-theming';

import Layout from '../layout/Layout';
import SocialLinks from '../components/SocialLinks/SocialLinks';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import { PostInfo } from '../components/PostInfo';
import { NewsletterIframe } from '../components/NewsletterIframe';
import { Article } from './styles';
import { SpacingContainer } from '../components/styles';
import { HighlightButton } from '../components/Buttons.js';
import { ThemeContext } from '../theming/ThemeContext';

const preprocessHeading = h => {
  return {
    depth: h.depth,
    value: h.value,
    id: slugs.slug(h.value),
  };
};

const PostTemplate = props => {
  const { data, pageContext } = props;
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  const { fancyDate } = postNode.fields;
  const theme = useTheme();
  const themeContext = useContext(ThemeContext);

  const headings = data.markdownRemark.headings
    .filter(h => h.depth >= 2 && h.depth <= 4)
    .map(preprocessHeading);
  // only show TOC if there are 3 or more headers, otherwise what's the point
  const withToc = headings.length > 2;
  const activeHeadingId = useActiveHeading(headings);
  const canonicalUrl = post.canonical || `${config.websiteUrl}${slug}`;

  return (
    <ThemeProvider
      theme={{
        ...themeContext.theme,
        containerSize: 'var(--container-size-toc)',
      }}
    >
      <Layout>
        <Helmet
          title={`${post.title} | ${config.siteTitle}`}
          description={postNode.excerpt}
        >
          <link rel='canonical' href={canonicalUrl} />
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />

        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <Article css={{ maxWidth: theme.contentSize, width: '100%' }}>
            <SpacingContainer as='h1' marginBottom={0}>
              {post.title}
            </SpacingContainer>

            <SpacingContainer marginBottom='40px' marginTop={'12px'}>
              <PostInfo timeToRead={postNode.timeToRead} />
              {post.categories && (
                <small>
                  {' - '}
                  {post.categories.map((category, index) => (
                    <React.Fragment key={category}>
                      <Link to={`/${category}/`}>{category}</Link>
                      {index < post.categories.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </small>
              )}
              {' - '}
              <a
                href='https://twitter.com/intent/follow?screen_name=obedparla'
                target={'_blank'}
                css={{ boxShadow: 'none', position: 'relative', top: '6px' }}
              >
                <img
                  src='https://img.shields.io/twitter/follow/obedparla?label=Follow&style=social&color=#ccc'
                  alt='Follow Obed on Twitter badge'
                  css={{ width: 'auto' }}
                />
              </a>
              {' - '}
              <small>
                <Link to='/newsletter/'>Subscribe</Link>{' '}
              </small>
            </SpacingContainer>

            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />

            <a
              css={css`
                font-size: 1.2rem;
                display: inline-block;
                margin-bottom: 6px;
                box-shadow: none;

                ${theme.media.tinyMobile} {
                  width: 100%;
                  text-align: center;
                }
              `}
              href={`https://mobile.twitter.com/search?q=${config.websiteUrl}${slug}`}
            >
              Discuss on Twitter
            </a>

            <div
              css={css`
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                ${theme.media.tinyMobile} {
                  flex-direction: column-reverse;
                }
              `}
            >
              <div
                css={css`
                  ${theme.media.tinyMobile} {
                    width: 100%;
                    text-align: center;
                  }
                `}
              >
                <div
                  css={{ fontSize: '14px', color: theme.articleHeadingColor }}
                >
                  LAST UPDATED
                </div>
                <div css={{ fontSize: '20px' }}>{fancyDate}</div>
              </div>
              <SocialLinks postPath={slug} postNode={postNode} />
            </div>

            <NewsletterIframe margin={'2.2rem 0 1.7rem'} />
          </Article>

          {withToc && (
            <div
              css={css`
                max-width: 250px;
                align-items: baseline;
                margin: 20px 0 0 100px;

                @media (max-width: 1100px) {
                  display: none;
                }
              `}
            >
              {post.cover && <Img fluid={post.cover.childImageSharp.fluid} />}
              <aside
                css={css`
                  position: sticky;
                  top: 100px;
                  align-self: self-start;
                `}
              >
                <p
                  css={{
                    fontSize: 15,
                    color: theme.softTextColor,
                    marginTop: 20,
                  }}
                >
                  Hey! I'm Obed and I write <b>visual articles</b> to help you
                  learn <b>JavaScript</b> and <b>React</b> in better ways. I
                  also write about productivity and engineering.
                </p>
                <div css={{ textAlign: 'center' }}>
                  <HighlightButton
                    css={{ padding: '8px 16px' }}
                    to={'/newsletter'}
                  >
                    Get curated articles
                  </HighlightButton>
                </div>

                <h2
                  css={css`
                    margin: 40px 0 10px;
                    font-size: 18px;
                    text-align: center;
                  `}
                >
                  TABLE OF CONTENTS
                </h2>
                <div
                  css={css`
                    max-height: 400px;
                    overflow: auto;
                  `}
                >
                  {headings.map(h => (
                    <a
                      key={h.id}
                      href={`#${h.id}`}
                      css={css`
                        display: block;
                        font-size: ${h.depth > 2 ? 14 : 15}px;
                        margin-left: ${h.depth > 2 ? 5 * h.depth : 0}px;
                        margin-top: ${h.depth > 2 ? 5 : 10}px;
                        box-shadow: none;
                        line-height: 1.7;
                        color: ${activeHeadingId === h.id
                          ? theme.strongHighlightColor
                          : theme.softTextColor};

                        :hover {
                          color: ${theme.textColor};
                        }
                      `}
                    >
                      {h.value}
                    </a>
                  ))}
                </div>
              </aside>
            </div>
          )}
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default PostTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        categories
        cover {
          childImageSharp {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        canonical
      }
      fields {
        slug
        fancyDate
      }
      headings {
        depth
        value
      }
    }
  }
`;

function throttle(callback, limit) {
  let wait = false; // Initially, we're not waiting
  return function() {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(function() {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
}

// Copied from the amazing Josh Comeau's website
// https://joshwcomeau.com/
const useActiveHeading = headings => {
  const [activeHeadingId, setActiveHeading] = React.useState(null);

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      // If we're all the way at the top, there is no active heading.
      // This is done because "Introduction", the first link in the TOC, will
      // be active if `heading` is `null`.
      if (window.pageYOffset === 0) {
        return setActiveHeading(null);
      }

      // There HAS to be a better single-step algorithm for this, but I can't
      // think of it. So I'm doing this in 2 steps:
      //
      // 1. Are there any headings in the viewport right now? If so, pick the
      //    top one.
      // 2. If there are no headings in the viewport, are there any above
      //    the viewport? If so, pick the last one (most recently scrolled out
      //    of view)
      //
      // If neither condition is met, I'll assume I'm still in the intro,
      // although this would have to be a VERY long intro to ever be true.

      const headingBoxes = headings.map(({ id }) => {
        const elem = document.querySelector(`#${id}`);
        return { id, box: elem.getBoundingClientRect() };
      });

      // The first heading within the viewport is the one we want to highlight.
      // Because our heading obscures the top ~60px of the window, I'm
      // considering that range out-of-viewport.
      const TOP_OFFSET = 100;
      let firstHeadingInViewport = headingBoxes.find(({ box }) => {
        return box.bottom > TOP_OFFSET && box.top < window.innerHeight;
      });

      // If there is no heading in the viewport, check and see if there are any
      // above the viewport.
      if (!firstHeadingInViewport) {
        const reversedBoxes = [...headingBoxes].reverse();

        firstHeadingInViewport = reversedBoxes.find(({ box }) => {
          return box.bottom < TOP_OFFSET;
        });
      }

      if (!firstHeadingInViewport) {
        setActiveHeading(null);
      } else if (firstHeadingInViewport.id !== activeHeadingId) {
        setActiveHeading(firstHeadingInViewport.id);
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeHeadingId, headings]);

  return activeHeadingId;
};

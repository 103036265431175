import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import urljoin from 'url-join';
import config from '../../../data/SiteConfig';
import { StyledSocialLinks } from './styles';

const SocialLinks = props => {
  const { postNode, postPath, mobile } = props;
  const post = postNode.frontmatter;
  const url = urljoin(config.siteUrl, config.pathPrefix, postPath);
  const iconSize = mobile ? 36 : 48;
  const theme = useTheme();

  return (
    <div
      css={css`
        text-align: right;

        ${theme.media.tinyMobile} {
          width: 100%;
          text-align: center;
          margin: 20px 0;
        }
      `}
    >
      <div css={{ fontSize: '14px', color: theme.articleHeadingColor }}>
        TELL YOUR FRIENDS
      </div>
      <StyledSocialLinks>
        <TwitterShareButton
          url={url}
          title={`I enjoyed reading "${post.title}" by @obedparla today`}
        >
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={postNode.excerpt}>
          <FacebookIcon round size={iconSize} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          title={post.title}
          description={postNode.excerpt}
        >
          <LinkedinIcon round size={iconSize} />
        </LinkedinShareButton>
        <RedditShareButton url={url} title={post.title}>
          <RedditIcon round size={iconSize} />
        </RedditShareButton>
      </StyledSocialLinks>
    </div>
  );
};

export default SocialLinks;

import styled from '@emotion/styled';

export const StyledSocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 4px;

  & > div {
    cursor: pointer;
  }

  & > div > div {
    display: flex;
    align-items: center;
  }

  & > div:hover {
    svg {
      height: 45px;
      width: 45px;
    }
  }

  & svg {
    transition: all 0.2s ease-out;
    height: 36px;
    width: 36px;
  }
`;
